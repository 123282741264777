import Footer from "../components/Footer";
import Header from "../components/Header";

function Terms() {
  return (
    <div className="terms">
      <Header />
      <section className="bg-white dark:bg-gray-900">
        <div className="container w-full md:max-w-3xl mx-auto pt-20">
          <div className="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal">
            <div className="font-sans">
              <h1 className="font-bold font-sans break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
                Termos de Serviço Learn To Code
              </h1>
              <p className="text-sm md:text-base font-normal text-gray-600 dark:text-gray-300">
                Última atualização: 02 de setembro de 2022
              </p>
            </div>

            <p className="py-6 dark:text-white">
              Por favor, leia estes termos e condições cuidadosamente antes de
              usar Nosso Serviço.
            </p>

            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              1. Interpretação e Definições
            </h1>

            <p className="py-6 dark:text-white">
              As palavras cuja letra inicial é maiúscula têm significados
              definidos nas seguintes condições. As seguintes definições terão o
              mesmo significado, independentemente de aparecerem no singular ou
              no plural.
            </p>

            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              2. Definições
            </h1>

            <p className="py-6 dark:text-white">
              Para efeitos destes Termos e Condições:
            </p>

            <div className="pl-10 dark:text-white">
              <li className="py-3 dark:text-white">
                <strong>Afiliada</strong> significa uma entidade que controla, é
                controlada ou está sob controle comum com uma parte, onde
                "controle" significa propriedade de 50% ou mais das ações,
                participação acionária ou outros valores mobiliários com direito
                a voto para eleição de diretores ou outra autoridade
                administrativa.
              </li>
              <li className="py-3 dark:text-white">
                <strong>País</strong> se refere a: Brasil
              </li>
              <li className="py-3 dark:text-white">
                <strong>Empresa</strong> (referida como "a Empresa", "Nós",
                "Nós" ou "Nosso" neste Contrato) refere-se ao Learn To Code.
              </li>
              <li className="py-3 dark:text-white">
                <strong>Dispositivo</strong> significa uma entidade que
                controla, é controlada ou está sob controle comum com uma parte,
                onde "controle" significa propriedade de 50% ou mais das ações,
                participação acionária ou outros valores mobiliários com direito
                a voto para eleição de diretores ou outra autoridade
                administrativa.
              </li>
              <li className="py-3 dark:text-white">
                <strong>Serviço</strong> significa uma entidade que controla, é
                controlada ou está sob controle comum com uma parte, onde
                "controle" significa propriedade de 50% ou mais das ações,
                participação acionária ou outros valores mobiliários com direito
                a voto para eleição de diretores ou outra autoridade
                administrativa.
              </li>
              <li className="py-3 dark:text-white">
                <strong>Termos e Condições</strong> significa uma entidade que
                controla, é controlada ou está sob controle comum com uma parte,
                onde "controle" significa propriedade de 50% ou mais das ações,
                participação acionária ou outros valores mobiliários com direito
                a voto para eleição de diretores ou outra autoridade
                administrativa.
              </li>
              <li className="py-3 dark:text-white">
                <strong>Serviço de mídia social de terceiro</strong> significa
                qualquer serviço ou conteúdo (incluindo dados, informações,
                produtos ou serviços) fornecido por um terceiro que possa ser
                exibido, incluído ou disponibilizado pelo Serviço.
              </li>
              <li className="py-3 dark:text-white">
                <strong>O site</strong> refere-se ao Learn To Code, acessível em
                https://www.learntocode.games.
              </li>
              <li className="py-3 dark:text-white">
                <strong>Você</strong> significa o indivíduo acessando ou usando
                o Serviço, ou a empresa ou outra entidade legal em nome da qual
                tal indivíduo está acessando ou usando o Serviço, conforme
                aplicável.
              </li>
            </div>

            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              3. Reconhecimento
            </h1>

            <p className="py-4 dark:text-white">
              Estes são os Termos e Condições que regem o uso deste Serviço e o
              contrato que opera entre você e a Empresa. Estes Termos e
              Condições estabelecem os direitos e obrigações de todos os
              usuários em relação ao uso do Serviço.
            </p>
            <p className="py-4 dark:text-white">
              Seu acesso e uso do Serviço está condicionado à sua aceitação e
              cumprimento destes Termos e Condições. Estes Termos e Condições se
              aplicam a todos os visitantes, usuários e outros que acessam ou
              usam o Serviço.
            </p>
            <p className="py-4 dark:text-white">
              Ao acessar ou usar o Serviço, você concorda em ficar vinculado a
              estes Termos e Condições. Se você discordar de qualquer parte
              destes Termos e Condições, não poderá acessar o Serviço.
            </p>
            <p className="py-4 dark:text-white">
              Seu acesso e uso do Serviço também está condicionado à sua
              aceitação e cumprimento da Política de Privacidade da Empresa.
              Nossa Política de Privacidade descreve nossas políticas e
              procedimentos sobre a coleta, uso e divulgação de suas informações
              pessoais quando você usa o Aplicativo ou o Site e informa sobre
              seus direitos de privacidade e como a lei protege você. Por favor,
              leia nossa Política de Privacidade cuidadosamente antes de usar
              Nosso Serviço.
            </p>

            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              4. Links para outros sites
            </h1>

            <p className="py-4 dark:text-white">
              Nosso Serviço pode conter links para sites ou serviços de
              terceiros que não são de propriedade ou controlados pela Empresa.
            </p>
            <p className="py-4 dark:text-white">
              A Empresa não tem controle e não assume nenhuma responsabilidade
              pelo conteúdo, políticas de privacidade ou práticas de quaisquer
              sites ou serviços de terceiros. Você também reconhece e concorda
              que a Empresa não será responsável, direta ou indiretamente, por
              qualquer dano ou perda causada ou alegadamente causada por ou em
              conexão com o uso ou dependência de qualquer conteúdo, bens ou
              serviços disponíveis em ou através de tais sites ou serviços.
            </p>
            <p className="py-4 dark:text-white">
              Recomendamos vivamente que leia os termos e condições e as
              políticas de privacidade de quaisquer sites ou serviços de
              terceiros que visite.
            </p>

            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              5. Terminação
            </h1>

            <p className="py-4 dark:text-white">
              Podemos encerrar ou suspender seu acesso imediatamente, sem aviso
              prévio ou responsabilidade, por qualquer motivo, incluindo, sem
              limitação, se você violar estes Termos e Condições.
            </p>
            <p className="py-4 dark:text-white">
              Após a rescisão, seu direito de usar o Serviço cessará
              imediatamente.
            </p>

            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              6. Limitação de responsabilidade
            </h1>

            <p className="py-4 dark:text-white">
              Na extensão máxima permitida pela lei aplicável, em nenhum caso a
              Empresa ou seus fornecedores serão responsáveis por quaisquer
              danos especiais, incidentais, indiretos ou consequentes
              (incluindo, mas não limitado a, danos por lucros cessantes, perda
              de dados ou outras informações, para interrupção de negócios,
              danos pessoais, perda de privacidade decorrente ou de alguma forma
              relacionada ao uso ou incapacidade de usar o Serviço, software de
              terceiros e/ou hardware de terceiros usado com o Serviço, ou de
              outra forma em conexão com qualquer disposição destes Termos),
              mesmo que a Empresa ou qualquer fornecedor tenha sido avisado da
              possibilidade de tais danos e mesmo que a solução não cumpra sua
              finalidade essencial.
            </p>
            <p className="py-4 dark:text-white">
              Alguns estados não permitem a exclusão de garantias implícitas ou
              limitação de responsabilidade por danos incidentais ou
              consequentes, o que significa que algumas das limitações acima
              podem não se aplicar. Nesses estados, a responsabilidade de cada
              parte será limitada ao máximo permitido por lei.
            </p>
            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              7. Lei Aplicável
            </h1>

            <p className="py-4 dark:text-white">
              As leis do País, excluindo seus conflitos de regras legais,
              regerão estes Termos e Seu uso do Serviço. Seu uso do Aplicativo
              também pode estar sujeito a outras leis locais, estaduais,
              nacionais ou internacionais.
            </p>
            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              8. Resolução de disputas
            </h1>

            <p className="py-4 dark:text-white">
              Se você tiver alguma dúvida ou disputa sobre o Serviço, você
              concorda em primeiro tentar resolver a disputa informalmente
              entrando em contato com a Empresa.
            </p>
            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              9. Para usuários da União Europeia (UE)
            </h1>

            <p className="py-4 dark:text-white">
              Se for um consumidor da União Europeia, beneficiará de quaisquer
              disposições obrigatórias da lei do país em que reside.
            </p>

            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              10. Conformidade Legal dos Estados Unidos
            </h1>

            <p className="py-4 dark:text-white">
              Você declara e garante que (i) você não está localizado em um país
              sujeito ao embargo do governo dos Estados Unidos ou que foi
              designado pelo governo dos Estados Unidos como um país de "apoio
              ao terrorismo" e (ii) Você não está listado em qualquer lista do
              governo dos Estados Unidos de partes proibidas ou restritas.
            </p>

            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              11. Divisibilidade e Renúncia
            </h1>

            <li className="py-3 dark:text-white">
              <strong>Divisibilidade</strong> se qualquer disposição destes
              Termos for considerada inexequível ou inválida, tal disposição
              será alterada e interpretada para atingir os objetivos de tal
              disposição na maior extensão possível sob a lei aplicável e as
              disposições restantes continuarão em pleno vigor e efeito.
            </li>
            <li className="py-3 dark:text-white">
              <strong>Renúncia</strong> exceto conforme previsto neste
              documento, a falha em exercer um direito ou exigir o cumprimento
              de uma obrigação sob estes Termos não afetará a capacidade de uma
              parte de exercer tal direito ou exigir tal desempenho a qualquer
              momento posterior, nem a renúncia de uma violação constituirá uma
              renúncia de qualquer violação posterior.
            </li>

            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              12. Interpretação de tradução
            </h1>

            <p className="py-4 dark:text-white">
              Estes Termos e Condições podem ter sido traduzidos se os tivermos
              disponibilizado a você em nosso Serviço. Você concorda que o texto
              original em inglês prevalecerá em caso de disputa.
            </p>

            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              13. Alterações a estes Termos e Condições
            </h1>

            <p className="py-4 dark:text-white">
              Reservamo-nos o direito, a Nosso exclusivo critério, de modificar
              ou substituir estes Termos a qualquer momento. Se uma revisão for
              material faremos esforços razoáveis para fornecer um aviso prévio
              de pelo menos 30 dias antes de quaisquer novos termos entrarem em
              vigor. O que constitui uma mudança material será determinado a
              nosso exclusivo critério.
            </p>
            <p className="py-4 dark:text-white">
              Ao continuar a acessar ou usar Nosso Serviço após essas revisões
              entrarem em vigor, você concorda em ficar vinculado aos termos
              revisados. Se você não concordar com os novos termos, no todo ou
              em parte, pare de usar o site e o Serviço.
            </p>
            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl dark:text-white">
              14. Jogos e Serviço
            </h1>

            <p className="py-4 dark:text-white">
              Todos os títulos e direitos sobre o Serviço (incluindo, mas não
              limitado a, jogos, títulos, códigos de computador, temas, objetos,
              personagens, nomes de personagens, histórias, diálogos, bordões,
              conceitos, trabalhos de arte, animações, sons, composições
              musicais, efeitos audiovisuais, métodos de operação, direitos
              morais, documentação, transcrições de bate-papo dentro do jogo,
              informações de perfil de personagens, gravações de jogos jogados
              por meio do uso de um cliente de jogo da Learn To Code e dos
              clientes de jogo da Learn To Code e softwares de servidor) são de
              propriedade da Learn To Code.
            </p>
            <p className="py-4 dark:text-white">
              A Learn To Code reserva para si todos os direitos, incluindo, mas
              não limitado a direitos de propriedade intelectual ou outros
              direitos de propriedade relacionados aos jogos e ao Serviço.
            </p>

            <h1 className="font-bold break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl  dark:text-white">
              15. Contate-nos
            </h1>

            <p className="py-4 dark:text-white">
              Se você tiver alguma dúvida sobre estes Termos e Condições, você
              pode entrar em contato conosco:
            </p>
            <div className="py-4 dark:text-white">
              <li className="py-3 dark:text-white">
                Por e-mail: contato@learntocode.games
              </li>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Terms;
