import { createContext, useContext } from "react";

export const AuthContext = createContext({} as any);

export function AuthProvider({ children, value }: any) {
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthValue() {
  return useContext(AuthContext);
}
