import { useAuthValue } from "./AuthContext";
import { useState, useEffect } from "react";
import { auth } from "../services/firebase";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/LearnToCode_Black.png";
import sendVerificationEmail from "../services/sendEmail";
import { sendEmailVerification } from "@firebase/auth";

function VerifyEmail() {
  const { currentUser } = useAuthValue();
  const [time, setTime] = useState(60);
  const { timeActive, setTimeActive } = useAuthValue();
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      currentUser
        ?.reload()
        .then(() => {
          if (currentUser?.emailVerified) {
            navigate("/");
          }
        })
        .catch((err: any) => console.log(err.message));
    }, 1000);
    return () => clearInterval(interval);
  }, [currentUser, navigate]);

  useEffect(() => {
    let interval: any = null;
    if (timeActive && time !== 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    } else if (time === 0) {
      setTimeActive(false);
      setTime(60);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timeActive, time, setTimeActive]);

  const resendEmailVerification = () => {
    sendEmailVerification(auth.currentUser!)
      .then(() => {
        setTimeActive(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div className="center">
      <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden bg-white py-6 sm:py-12">
        <img width={"250px"} src={Logo} alt="Learn To Code" className="py-10" />
        <div className="max-w-xl px-5 text-center">
          <h2 className="mb-2 text-[42px] font-bold text-zinc-800">
            Verifique sua caixa de entrada
          </h2>
          <p className="mb-2 text-lg text-zinc-500">
            Enviamos um link de verificação para o endereço de e-mail{" "}
            <span className="font-medium text-indigo-500">
              {currentUser?.email}
            </span>
          </p>
          <span className="font-medium">
            Siga as instruções no e-mail para verificar sua conta
          </span>
          <button
            className="mt-3 inline-block w-96 rounded bg-blue-700 font-medium text-white shadow-md shadow-indigo-500/20 hover:bg-blue-800"
            onClick={resendEmailVerification}
            disabled={timeActive}
          >
            Reenviar email {timeActive && time}
          </button>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
