import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import "aos/dist/aos.css";

import AOS from "aos";

import Home from "./pages/Home";
import SignIn from "./pages/Login";
import SignUp from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import { Event } from "./pages/Event";
import { AuthProvider } from "./pages/AuthContext";
import Terms from "./pages/Terms";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./services/firebase";
import PrivateRoute from "./pages/PrivateRoute";
import VerifyEmail from "./pages/VerifyEmail";
import { ThemeContext } from "./context/ThemeContext";
// import Profile from "./pages/Profile";

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [timeActive, setTimeActive] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  }, []);

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  });

  const HTMLElement = document.querySelector("html");

  useEffect(() => {
    HTMLElement.style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    HTMLElement.style.scrollBehavior = "";
  }, [HTMLElement]);

  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  return (
    <Router>
      <AuthProvider value={{ currentUser, timeActive, setTimeActive }}>
        <Routes>
          <Route
            path="/"
            element={
              <ThemeContext.Provider value={{ theme, toggleTheme }}>
                <div className={`${theme}`}>
                  <Home />
                </div>
              </ThemeContext.Provider>
            }
          />
          <Route
            path="/cursos/:slug"
            element={
              <ThemeContext.Provider value={{ theme, toggleTheme }}>
                <div className={`${theme}`}>
                  <PrivateRoute>
                    <Event />
                  </PrivateRoute>
                </div>
              </ThemeContext.Provider>
            }
          />
          <Route
            path="/login"
            element={
              !currentUser?.emailVerified ? (
                <ThemeContext.Provider value={{ theme, toggleTheme }}>
                  <div className={`${theme}`}>
                    <SignIn />
                  </div>
                </ThemeContext.Provider>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/register"
            element={
              !currentUser?.emailVerified ? (
                <ThemeContext.Provider value={{ theme, toggleTheme }}>
                  <div className={`${theme}`}>
                    <SignUp />
                  </div>
                </ThemeContext.Provider>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="terms" element={<Terms />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
