import { Navigate } from "react-router-dom";
import { useAuthValue } from "./AuthContext";

export default function PrivateRoute({ children }: any) {
  const { currentUser } = useAuthValue();

  if (!currentUser?.emailVerified) {
    return <Navigate to="/register" replace />;
  }

  return children;
}
