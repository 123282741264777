import { useGetLessonsQuery } from "../services/graphql/generated";
import { Lesson } from "./Lesson";

function FeaturesBlocks() {
  const { data } = useGetLessonsQuery();

  return (
    <section id="cursos" className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
        <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Nossos cursos
          </h2>
          <p className="font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">
            Explore todos os nossos cursos e aprofunde seus conhecimentos em
            diversas áreas computacionais.
          </p>
        </div>
        <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none"></div>
        <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
          {!data && (
            <div className="max-w-sm rounded-lg border border-gray-200 bg-gray-900 p-6 shadow-md dark:border-gray-700 dark:bg-gray-800">
              <div className="animate-pulse">
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-2 h-2 rounded bg-slate-700"></div>
                    <div className="col-span-1 h-4 rounded bg-slate-700"></div>
                  </div>
                  <div className="h-2 rounded bg-slate-700"></div>
                </div>
                <div className="mt-6 h-3 rounded bg-slate-700"></div>
                <div className="mt-2 h-3 rounded bg-slate-700"></div>
              </div>
            </div>
          )}
          {data?.lessons.map((lesson) => {
            return (
              <Lesson
                key={lesson.id}
                title={lesson.title}
                slug={lesson.slug as string}
                availableAt={new Date(lesson.availableAt)}
                type={lesson.lessonType}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
