import Header from "../components/Header";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import FeaturesBlocks from "../components/FeaturesBlocks";

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden dark:bg-gray-900">
      <Header />
      <main className="flex-grow">
        <Hero />
        <FeaturesBlocks />
      </main>
      <Footer />
    </div>
  );
}

export default Home;
