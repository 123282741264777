import { useState, useEffect, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";

import LogoWhite from "../assets/LearnToCode_White.png";
import LogoBlack from "../assets/LearnToCode_Black.png";

import { auth } from "../services/firebase";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import DarkSwitch from "./DarkSwitch";
import { ThemeContext } from "../context/ThemeContext";

function Header() {
  const { theme }: any = useContext(ThemeContext);
  const [top, setTop] = useState(true);
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  async function signOut() {
    await auth.signOut();
  }

  const user = auth.currentUser;

  return (
    <Disclosure
      as="nav"
      className="bg-white px-2 sm:px-4 py-2.5 dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/">
                    <img
                      width={"100px"}
                      src={theme === "dark" ? LogoWhite : LogoBlack}
                      alt="Learn To Code"
                    />
                  </Link>
                </div>
              </div>

              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <DarkSwitch />
                  {user ? (
                    <div className="flex md:order-3 space-x-6">
                      {!user.photoURL ? (
                        <Avatar
                          size="40"
                          className="h-10 w-10 rounded-full"
                          name={user.displayName || user.email || "User"}
                        />
                      ) : (
                        <img
                          className="h-10 w-10 rounded-full"
                          src={user.photoURL}
                          alt="avatar"
                        />
                      )}
                      <button
                        onClick={signOut}
                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                      >
                        Sair
                      </button>
                    </div>
                  ) : (
                    <div className="flex md:order-2">
                      <Link
                        to={"/register"}
                        className="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
                      >
                        Inscreva-se
                      </Link>
                      <Link
                        to={"/login"}
                        className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                      >
                        Entrar
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="border-t border-gray-700 pt-4 pb-3">
              {user ? (
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    {!user.photoURL ? (
                      <Avatar
                        size="40"
                        className="h-10 w-10 rounded-full"
                        name={user.displayName || user.email || "User"}
                      />
                    ) : (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user.photoURL}
                        alt="avatar"
                      />
                    )}
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">
                      {user.displayName}
                    </div>
                    <div className="text-sm font-medium leading-none text-gray-400">
                      {user.email}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <Link
                    to={"/register"}
                    className="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
                  >
                    Inscreva-se
                  </Link>
                  <Link
                    to={"/login"}
                    className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                  >
                    Entrar
                  </Link>
                </>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default Header;
