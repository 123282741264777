import { createContext, useState } from "react";

export const ThemeContext = createContext({});

export function ThemeSwith() {
  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  return <ThemeContext.Provider value={{ theme, toggleTheme } as any} />;
}
