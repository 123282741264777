import { CheckCircle, Lock } from "phosphor-react";
import { isPast, format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";

interface LessonProps {
  title: string;
  slug: string;
  availableAt: Date;
  type: "live" | "class";
}

export function Lesson(props: LessonProps) {
  const { slug } = useParams<{ slug: string }>();

  const isLessonAvailable = isPast(props.availableAt);
  const availableDateFormatted = format(
    props.availableAt,
    "d' de 'MMMM' às 'k'h'mm",
    {
      locale: ptBR,
    }
  );

  const isActiveLesson = slug === props.slug;

  return (
    <div className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
      <svg
        className="mb-2 w-10 h-10 text-gray-500 dark:text-gray-400"
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z"
          clipRule="evenodd"
        ></path>
        <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z"></path>
      </svg>
      <header className="flex items-center justify-between">
        {isLessonAvailable ? (
          <span
            className={classNames(
              "text-sm font-medium flex items-center gap-2",
              {
                "text-black": isActiveLesson,
                "text-blue-600": !isActiveLesson,
              }
            )}
          >
            <CheckCircle size={20} />
            Conteúdo liberado
          </span>
        ) : (
          <span className="text-sm text-orange-500 font-medium flex items-center gap-2">
            <Lock size={20} />
            Em breve
          </span>
        )}

        <span
          className={classNames(
            "text-xs rounded py-[0.125rem] px-2 text-black border font-bold dark:text-white dark:border-blue-500",
            {
              "border-black": isActiveLesson,
              "border-blue-600": !isActiveLesson,
            }
          )}
        >
          {props.type === "live" ? "AO VIVO" : "AULA PRÁTICA"}
        </span>
      </header>
      <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
        {props.title}
      </h5>
      <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
        Aprenda a montar um computador do zero e entenda como cada peça funciona
      </p>
      {isLessonAvailable ? (
        <Link
          to={`/cursos/${props.slug}`}
          className="inline-flex items-center text-blue-600 hover:underline"
        >
          Acessar
          <svg
            className="ml-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path>
            <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
          </svg>
        </Link>
      ) : (
        <p className="text-gray-500">Disponível em {availableDateFormatted}</p>
      )}
    </div>
  );
}
