import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC7L_7swZgG5RCBVjcmboSq3qXkAJ1xxVM",
  authDomain: "learntocode-firebase.firebaseapp.com",
  projectId: "learntocode-firebase",
  storageBucket: "learntocode-firebase.appspot.com",
  messagingSenderId: "971039575607",
  appId: "1:971039575607:web:dab25042ac73833f56b9b2",
};

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
