import React, { memo, useMemo, useCallback, useEffect, useRef } from "react";
import { CaretRight, GithubLogo, FileArrowDown, Lightning } from "phosphor-react";
import { useGetLessonBySlugQuery } from "../services/graphql/generated";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Link, useNavigate } from "react-router-dom";

interface GameProps {
  lessonSlug: string;
}

const UnityGame = memo(({ lessonSlug }: GameProps) => {
  const iframeRef = useRef<HTMLDivElement>(null);
  const unityFrameRef = useRef<HTMLCanvasElement>(null);
  const navigate = useNavigate();

  const unityContext = useMemo(() => ({
    codeUrl: `/${lessonSlug}/${lessonSlug}.wasm`,
    dataUrl: `/${lessonSlug}/${lessonSlug}.data`,
    frameworkUrl: `/${lessonSlug}/${lessonSlug}.framework.js`,
    loaderUrl: `/${lessonSlug}/${lessonSlug}.loader.js`,
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  }), [lessonSlug]);

  const { unityProvider, loadingProgression, isLoaded } = useUnityContext(unityContext);

  useEffect(() => {
    if (unityFrameRef.current) {
      unityFrameRef.current.addEventListener("unityLoaded", () => {
        unityFrameRef.current?.focus();
      });

      unityFrameRef.current.addEventListener("unityUnloaded", () => {
        navigate("/");
      });
    }
  }, [unityFrameRef.current]);

  const { data } = useGetLessonBySlugQuery({
    variables: {
      slug: lessonSlug,
    },
  });

  if (!data || !data.lesson) {
    return (
      <div className="flex-1 text-white">
        <p>Carregando...</p>
      </div>
    );
  }

  return (
    <div className="flex-1 dark:bg-gray-900">
      <div className="dark:bg-black bg-gray-100 flex justify-center">
        <div
          className="h-full w-full max-w-[800px] max-h-[800px] aspect-video"
          ref={iframeRef} // Removed "as any"
        >
          {!isLoaded && (
            <p className="bg-white">
              Jogo em carregamento... {Math.round(loadingProgression * 100)}%
            </p>
          )}
          <Unity
            unityProvider={unityProvider}
            className="h-full w-full max-w-[800px] max-h-[800px] aspect-video"
            style={{ visibility: isLoaded ? "visible" : "hidden" }}
            ref={unityFrameRef} // Removed "as any"
          />
        </div>
      </div>

      <div className="dark:text-gray-100 text-gray-900 p-8 max-w-[1100px] mx-auto">
        <div className="flex items-start gap-16">
          <div className="flex-1">
            <h1 className="text-2xl font-bold">{data.lesson.title}</h1>
            <p className="mt-4 dark:text-gray-200 text-gray-900 leading-relaxed">
              {data.lesson.description}
            </p>

            {data.lesson.teacher && (
              <div className="flex items-center gap-4 mt-6">
                <img
                  className="h-16 w-16 rounded-full border-2 border-blue-500"
                  src={data.lesson.teacher.avatarURL}
                  alt=""
                />

                <div className="leading-relaxed">
                  <strong className="font-bold text-2xl block">
                    {data.lesson.teacher.name}
                  </strong>
                  <span className="dark:text-gray-200 text-gray-900 text-sm block">
                    {data.lesson.teacher.bio}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-4">
            <a
              href="https://github.com/LearnToCode-Games"
              className="p-4 text-sm bg-green-500 flex items-center rounded font-bold uppercase gap-2 justify-center hover:bg-green-700 transition-colors"
            >
              <GithubLogo size={24} />
              Acesse o GitHub
            </a>

            <Link
              to="/"
              className="p-4 text-sm border border-blue-500 text-blue-500 flex items-center rounded font-bold uppercase gap-2 justify-center hover:bg-blue-500 hover:text-gray-900 transition-colors"
            >
              <Lightning size={24} />
              Página Inicial
            </Link>
          </div>
        </div>

        <div className="gap-8 mt-20 grid grid-cols-2">
          <a
            href="https://www.figma.com/"
            className="bg-gray-700 rounded overflow-hidden flex items-stretch gap-6 hover:bg-gray-600 transition-colors"
          >
            <div className="bg-green-700 h-full p-6 flex items-center">
              <FileArrowDown size={40} />
            </div>
            <div className="py-6 leading-relaxed">
              <strong className="text-2xl text-gray-200">
                Material complementar
              </strong>
              <p className="text-sm text-gray-200 mt-2">
                Acesse o material complementar para acelerar o seu
                desenvolvimento
              </p>
            </div>
            <div className="h-full p-6 flex items-center">
              <CaretRight size={24} />
            </div>
          </a>

          <a
            href="https://www.figma.com/"
            className="rounded overflow-hidden flex items-stretch gap-6 hover:bg-gray-600 transition-colors dark:bg-gray-800 bg-gray-200"
          >
            <div className="h-full p-6 flex items-center dark:bg-green-700 bg-green-500">
              <FileArrowDown size={40} />
            </div>
            <div className="py-6 leading-relaxed">
              <strong className="text-2xl dark:text-gray-200 text-gray-900">
                Wallpapers exclusivos
              </strong>
              <p className="text-sm dark:text-gray-200 text-gray-900 mt-2">
                Baixe wallpapers exclusivos do Ignite Lab e personalize a sua
                máquina
              </p>
            </div>
            <div className="h-full p-6 flex items-center">
              <CaretRight size={24} />
            </div>
          </a>
        </div>
      </div >
    </div >
  );
});

export default UnityGame;