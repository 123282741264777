import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { MdOutlineDarkMode, MdOutlineWbSunny } from "react-icons/md";

export default function DarkSwitch() {
  const { theme, toggleTheme }: any = useContext(ThemeContext);
  return (
    <div className="switch">
      <button
        id="theme-toggle"
        type="button"
        onClick={toggleTheme}
        className="mr-2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
      >
        {theme === "light" ? (
          <MdOutlineDarkMode size={20} />
        ) : (
          <MdOutlineWbSunny size={20} />
        )}
      </button>
    </div>
  );
}
